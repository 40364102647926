import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import "./subscribe.scss";

export default function Subscribe({ data, location }) {

  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const url = new URL(window.location);
    const queryParams = new URLSearchParams(url.search);
    const token = queryParams.get("t");

    if (token) {
      updateSubscriptionStatus(token);
    }
  }, [location.search]);

  const updateSubscriptionStatus = async (token) => {
    try {
      const response = await fetch(
        "https://5mekv1w6e7.execute-api.us-east-1.amazonaws.com/default/Alulux-Subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "txQaQy18a3aSDEhnAUu8F8VI8D3ZhInU5aFqmNwa",
          },
          body: JSON.stringify({ token }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMessage("Thank you for confirming your subscription!");
        setIsError(false);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
      setIsError(true);
    }
  };

  return (
    <Layout
      pageTitle={"Subscribe"}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[2].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    >
      <div className="subscribe">
        {message && (
          <span className={isError ? "error-text" : "success-text"}>
            {message}
          </span>
        )}
      </div>
    </Layout>
  );
}

export const unsubscribePageData = graphql`
  query ProductsPageQuery {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            id
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                id
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on ContentfulButtonsContainer {
                        buttonsContainer {
                          socialMediaLink
                          socialMediaTitle
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;
